
import { defineComponent, onMounted, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { CategorySupplyService } from "@/core/services/CategorySupply.service";
import { CategorySupplyDataResponse } from "@/core/interfaces/ApiResponses";
import { VatTuService } from "@/core/services/VatTu.service";

interface SupplyForAutocomplete extends CategorySupplyDataResponse {
  value: string;
}

export default defineComponent({
  name: "supply-selection",
  emits: ["on-selected-supply"],
  setup() {
    const allCategorySupplies = ref<CategorySupplyDataResponse[]>([]);
    const categorySupplyId = ref(0);
    const quantity = ref(0);
    const supply = ref<SupplyForAutocomplete>({
      id: 0,
      ten_danh_muc: "",
      slug: "",
      mo_ta: "",
      id_cha: "",
      created_at: "",
      updated_at: "",
      value: "",
    });
    const selectedSupplyName = ref("");

    onMounted(async () => {
      const {
        data: {
          data: { data },
        },
      } = await CategorySupplyService.fetchAllCategorySupplies();

      allCategorySupplies.value = [...data];
    });

    return {
      allCategorySupplies,
      categorySupplyId,
      supply,
      quantity,
      selectedSupplyName,
    };
  },

  methods: {
    onCategorySelectedHandler(categoryId: number) {
      this.categorySupplyId = categoryId;
    },

    async querySupply(term: string, callback) {
      const {
        data: { data: items },
      } = await VatTuService.search(term, this.categorySupplyId);

      const results = items.map((item) => ({
        value: item.ten_vat_tu,
        ...item,
      }));
      if (results.length > 0) {
        callback(results);
      } else {
        callback([
          {
            value: "",
          },
        ]);
      }
    },

    onSelectedSupplyHandler(item) {
      this.selectedSupplyName = item.ten_vat_tu;
      this.supply = { ...item };
    },

    onSubmittedHandler() {
      if (this.quantity === 0) {
        Swal.fire({
          text: "Phải nhập số lượng",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Đã hiểu",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }
      if (this.selectedSupplyName === "") {
        Swal.fire({
          text: "Phải chọn vật tư",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Đã hiểu",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }
      this.$emit("on-selected-supply", {
        ...this.supply,
        quantity: this.quantity,
      });
      this.selectedSupplyName = "";
      this.quantity = 0;
    },
  },
});
