import {
  ApiResponse,
  CategoryProductItemResponse,
} from "@/core/interfaces/ApiResponses";
import { apiServiceInstance } from "@/core/helpers/utils";

export default class CategoryProductService {
  static list(): Promise<{
    data: ApiResponse<{
      data: CategoryProductItemResponse[];
      current_page: number;
      first_page_url: string;
      from: number;
      last_page: number;
      last_page_url: string;
      next_page_url: string | null;
      per_page: number;
      prev_page_url: string | null;
      to: number;
      total: number;
    }>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      "/api/v1/danh-muc-san-pham/danh-sach"
    );
  }

  static get(id: number): Promise<{
    data: ApiResponse<CategoryProductItemResponse>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/danh-muc-san-pham/chi-tiet/${id}`
    );
  }

  static create(
    name: string,
    description: string,
    parentId: number
  ): Promise<{
    data: ApiResponse<CategoryProductItemResponse>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    bodyFormData.append("danhMucSanPhamInstance[ten_danh_muc]", name);
    bodyFormData.append("danhMucSanPhamInstance[mo_ta]", description);
    bodyFormData.append("danhMucSanPhamInstance[id_cha]", parentId.toString());
    return apiServiceInstance().vueInstance.axios.post(
      "/api/v1/danh-muc-san-pham/tao-moi",
      bodyFormData
    );
  }

  static update(
    id: number,
    slug: string,
    name: string,
    description: string,
    parentId: number
  ): Promise<{
    data: ApiResponse<CategoryProductItemResponse>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    bodyFormData.append("danhMucSanPhamInstance[ten_danh_muc]", name);
    bodyFormData.append("danhMucSanPhamInstance[slug]", slug);
    bodyFormData.append("danhMucSanPhamInstance[mo_ta]", description);
    bodyFormData.append("danhMucSanPhamInstance[id_cha]", parentId.toString());
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/danh-muc-san-pham/cap-nhat/${id}`,
      bodyFormData
    );
  }

  static assignProducts(categoryProductId: number, productIds: number[]) {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    productIds.forEach((id) => {
      bodyFormData.append("sanPhamInstance[]", id.toString());
    });
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/danh-muc-san-pham/assign/${categoryProductId}`,
      bodyFormData
    );
  }
}
