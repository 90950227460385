
import { defineComponent, PropType } from "vue";

import { formatCurrencyAndQuantity } from "@/core/helpers/utils";
import moment from "moment";
import { SupplyItemForRepairingPayload } from "@/core/interfaces/Models";

export default defineComponent({
  name: "selected-supplies",
  emits: ["on-delete-clicked"],
  data() {
    return {
      formatCurrencyAndQuantity,
      moment,
    };
  },
  props: {
    items: {
      type: Array as PropType<Array<SupplyItemForRepairingPayload>>,
    },
    isCreated: {
      type: Boolean,
      default: true,
    },
    isDetail: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onClickDeleteHandler(id) {
      this.$emit("on-delete-clicked", id);
    },
  },
});
