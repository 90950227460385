
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";

import {
  CategoryProductItemResponse,
  CategorySupplyDataResponse,
  ProductItemResponse,
  SupplyWithPivotResponse,
} from "@/core/interfaces/ApiResponses";
import CategoryProductService from "@/core/services/CategoryProduct.service";
import { CategorySupplyService } from "@/core/services/CategorySupply.service";

import { PRODUCT_SELECTING_PROGRESS } from "@/core/constants/const";
import ProductService from "@/core/services/Product.service";

import ProductSelectionDialog from "@/views/crafted/pages/repairing-plans/product-selection-dialog/ProductSelectionDialog.vue";
import SupplySelection from "@/views/crafted/pages/repairing-plans/supply-selection/SupplySelection.vue";
import SelectedSupplies from "@/views/crafted/pages/repairing-plans/selected-supplies/SelectedSupplies.vue";
import {
  SupplyItemForRepairingPayload,
  SupplyRequestPayload,
} from "@/core/interfaces/Models";
import { RepairingPlanService } from "@/core/services/RepairingPlan.service";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "repairing-plan-form",
  components: { ProductSelectionDialog, SupplySelection, SelectedSupplies },
  data() {
    return {
      productSelectionProgress: PRODUCT_SELECTING_PROGRESS.NOT_SELECTING,
      PRODUCT_SELECTING_PROGRESS,
    };
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    // form elements
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);

    // local state
    const isCreated = ref(true);
    const repairingPlanForm = reactive({
      name: "",
      month: 0,
      note: "",
    });
    const selectedProductId = ref(0);
    const selectedProductName = ref("");
    const allCategories = ref<CategoryProductItemResponse[]>([]);
    const allCategorySupplies = ref<CategorySupplyDataResponse[]>([]);
    const productSelectingList = ref<ProductItemResponse[]>([]);
    const selectedSupplies = ref<SupplyItemForRepairingPayload[]>([]);

    // validation rule
    const rules = reactive({
      name: [
        {
          required: true,
          message: "Phải nhập tên của bản kế hoạch",
          trigger: "blur",
        },
      ],
      month: [
        {
          required: true,
          message: "Tháng bắt buộc phải nhập",
          trigger: "blur",
        },
      ],
      note: [
        {
          required: true,
          message: "Ghi chú bắt buộc phải nhập",
          trigger: "blur",
        },
      ],
    });

    onMounted(async () => {
      setCurrentPageTitle("Kế hoạch sửa chữa");
      isCreated.value = route.name === "new-repairing-plan";
      try {
        const {
          data: {
            data: { data },
          },
        } = await CategoryProductService.list();
        allCategories.value = [...data];

        const {
          data: {
            data: { data: categorySupplies },
          },
        } = await CategorySupplyService.fetchAllCategorySupplies();
        allCategorySupplies.value = [...categorySupplies];

        if (!isCreated.value) {
          const {
            data: { data: planResponse },
          } = await RepairingPlanService.get(+route.params.id);

          selectedProductName.value = planResponse.san_pham.ten_san_pham;
          selectedProductId.value = planResponse.san_pham.id;
          repairingPlanForm.name = planResponse.ten_ke_hoach;
          repairingPlanForm.month = planResponse.thang;
          repairingPlanForm.note = planResponse.ghi_chu;

          selectedSupplies.value = [];

          planResponse.dinh_muc_co_dinh.forEach(
            (item: SupplyWithPivotResponse) => {
              selectedSupplies.value.push({
                quantity: item.pivot.so_luong,
                id: item.id,
                is_valid: item.is_valid,
                ten_vat_tu: item.ten_vat_tu,
                ma_vat_tu: item.ma_vat_tu,
                so_luong: item.so_luong,
                don_vi_tinh: item.don_vi_tinh,
                don_gia: item.don_gia,
                muc_du_tru_toi_thieu: item.muc_du_tru_toi_thieu,
                muc_du_tru_toi_da: item.muc_du_tru_toi_da,
                thu_hoi: item.thu_hoi,
                cong_ty_duyet: item.cong_ty_duyet,
                id_tai_khoan: item.id_tai_khoan,
                nam: item.nam,
                slug: item.slug,
                updated_at: item.updated_at,
                created_at: item.created_at,
                vat_tu_meta:
                  item.vat_tu_meta?.length > 0 ? [...item.vat_tu_meta] : [],
                  ma_ct: item.ma_ct || "",
                ma_tk: item.ma_tk || "",
                ten_tk: item.ten_tk || "",
                so_du_dau_nam: item.so_du_dau_nam || 0,
              });
            }
          );
        }
      } catch (err) {
        Swal.fire({
          text: "Có lỗi xảy ra, mời bạn thử lại sau",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Đã hiểu!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        router.push("/trang-chu");
      }
    });

    const onSubmit = () => {
      if (!formRef.value) {
        return;
      }

      if (selectedProductId.value === 0) {
        Swal.fire({
          text: "Bạn chưa chọn toa xe nào",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Đã hiểu!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }

      if (selectedSupplies.value.length === 0) {
        Swal.fire({
          text: "Bạn chưa chọn vật tư nào",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Đã hiểu!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          let fetchedId = 0;
          const selectedSuppliesPayload: SupplyRequestPayload[] =
            selectedSupplies.value.map((item) => ({
              id: item.id,
              quantity: item.quantity,
            }));
          try {
            if (isCreated.value) {
              const {
                data: { data },
              } = await RepairingPlanService.create(
                repairingPlanForm.name,
                repairingPlanForm.month,
                repairingPlanForm.note,
                selectedProductId.value,
                selectedSuppliesPayload
              );
              fetchedId = data.id;
            } else {
              await RepairingPlanService.update(
                +route.params.id,
                repairingPlanForm.name,
                repairingPlanForm.month,
                repairingPlanForm.note,
                selectedProductId.value,
                selectedSupplies.value
              );
            }
            await Swal.fire({
              text: `Kế hoạch sửa chữa toa xe đã được ${
                isCreated.value ? "tạo mới" : "chỉnh sửa"
              } thành công`,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Đã hiểu",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            await router.push(
              `/quan-ly/ke-hoach-sua-chua/chi-tiet/${
                isCreated.value ? fetchedId : +route.params.id
              }`
            );
          } catch (error) {
            Swal.fire({
              text: `Xin lỗi, không thể ${
                isCreated.value ? "tạo mới" : "chỉnh sửa"
              } kế hoạch, mời bạn thực hiện lại`,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Đã hiểu",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          }
        } else {
          return false;
        }
      });
    };

    const resetForm = () => {
      repairingPlanForm.name = "";
      repairingPlanForm.month = 1;
      repairingPlanForm.note = "";
    };

    return {
      formRef,
      allCategories,
      allCategorySupplies,
      productSelectingList,
      selectedProductId,
      selectedProductName,
      selectedSupplies,
      rules,
      repairingPlanForm,
      isCreated,
      loading,
      onSubmit,
      resetForm,
    };
  },

  methods: {
    openProductSelectionDialog() {
      this.productSelectionProgress =
        PRODUCT_SELECTING_PROGRESS.SELECTING_CATEGORY;
    },

    async categorySelectedHandler(categoryProductId: number) {
      this.productSelectingList = [];
      try {
        const {
          data: {
            data: { san_pham },
          },
        } = await CategoryProductService.get(categoryProductId);
        this.productSelectionProgress =
          PRODUCT_SELECTING_PROGRESS.SELECTING_PRODUCT;
        if (san_pham && san_pham?.length > 0) {
          this.productSelectingList = [...san_pham];
        } else {
          this.productSelectingList = [];
        }
      } catch {
        Swal.fire({
          text: "Có lỗi xảy ra khi lựa chọn toa xe, mời bạn thử lại sau",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Đã hiểu!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    },

    async productSelectedHandler(productId: number) {
      try {
        const {
          data: {
            data: { id, ten_san_pham },
          },
        } = await ProductService.get(productId);
        this.selectedProductId = id;
        this.selectedProductName = ten_san_pham;
        this.productSelectionProgress = PRODUCT_SELECTING_PROGRESS.DONE;
      } catch {
        Swal.fire({
          text: "Có lỗi xảy ra khi lựa chọn toa xe, mời bạn thử lại sau",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Đã hiểu!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    },

    backToCategorySelecting() {
      this.productSelectionProgress =
        PRODUCT_SELECTING_PROGRESS.SELECTING_CATEGORY;
    },

    addVatTuToKeHoach(supply: SupplyItemForRepairingPayload) {
      const foundSupply = this.selectedSupplies.find(
        (item) => item.id === supply.id
      );
      if (foundSupply) {
        Swal.fire({
          text: "Vật tư này đã được chọn",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Đã hiểu!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }
      this.selectedSupplies = [...this.selectedSupplies, supply];
    },

    removeFromSelectedSupplies(supplyId: number) {
      this.selectedSupplies = this.selectedSupplies.filter(
        (item) => item.id !== supplyId
      );
    },
  },
});
