
import { defineComponent, PropType } from "vue";

import {
  CategoryProductItemResponse,
  ProductItemResponse,
} from "@/core/interfaces/ApiResponses";
import { PRODUCT_SELECTING_PROGRESS } from "@/core/constants/const";

export default defineComponent({
  name: "product-selection-dialog",
  emits: [
    "on-selected-category",
    "back-to-category-selecting",
    "on-selected-product",
  ],
  props: {
    allCategoryProducts: {
      type: Array as PropType<Array<CategoryProductItemResponse>>,
    },
    progress: {
      type: String as PropType<PRODUCT_SELECTING_PROGRESS>,
    },
    selectingProducts: {
      type: Array as PropType<Array<ProductItemResponse>>,
    },
  },

  data() {
    return {
      isDisplay: false,
      PRODUCT_SELECTING_PROGRESS,
    };
  },

  watch: {
    progress: {
      handler(newValue) {
        if (
          newValue !== PRODUCT_SELECTING_PROGRESS.NOT_SELECTING &&
          newValue !== PRODUCT_SELECTING_PROGRESS.DONE
        ) {
          this.isDisplay = true;
        } else {
          this.isDisplay = false;
        }
      },
      deep: true,
    },
  },

  computed: {
    titleDisplay() {
      switch (this.progress) {
        case PRODUCT_SELECTING_PROGRESS.SELECTING_CATEGORY:
          return "Chọn danh mục";
        default:
          return "Chọn toa xe";
      }
    },
  },

  methods: {
    categorySelectedHandler(categoryProductsId: number) {
      this.$emit("on-selected-category", categoryProductsId);
    },

    productSelectedHandler(productSelectedId: number) {
      this.$emit("on-selected-product", productSelectedId);
    },
    backToCategoryListButtonClickedHandler() {
      this.$emit("back-to-category-selecting");
    },
  },
});
