import {
  ApiResponse,
  ProductItemResponse,
} from "@/core/interfaces/ApiResponses";
import { apiServiceInstance } from "@/core/helpers/utils";

export default class ProductService {
  static list(): Promise<{
    data: ApiResponse<{
      data: ProductItemResponse[];
      current_page: number;
      first_page_url: string;
      from: number;
      last_page: number;
      last_page_url: string;
      next_page_url: string | null;
      per_page: number;
      prev_page_url: string | null;
      to: number;
      total: number;
    }>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      "/api/v1/san-pham/danh-sach"
    );
  }

  static get(id: number): Promise<{
    data: ApiResponse<ProductItemResponse>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/san-pham/chi-tiet/${id}`
    );
  }

  static create(
    name: string,
    code: string,
    codeNumber: string,
    description: string | null,
    note: string | null
  ): Promise<{
    data: ApiResponse<ProductItemResponse>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    bodyFormData.append("sanPhamInstance[ten_san_pham]", name);
    bodyFormData.append("sanPhamInstance[ma_san_pham]", code);
    bodyFormData.append("sanPhamInstance[so_hieu]", codeNumber);
    bodyFormData.append(
      "sanPhamInstance[mo_ta]",
      description ? description : ""
    );
    bodyFormData.append("sanPhamInstance[ghi_chu]", note ? note : "");
    return apiServiceInstance().vueInstance.axios.post(
      "/api/v1/san-pham/tao-moi",
      bodyFormData
    );
  }

  static update(
    id: number,
    name: string,
    code: string,
    codeNumber: string,
    description: string | null,
    note: string | null
  ): Promise<{
    data: ApiResponse<ProductItemResponse>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    bodyFormData.append("sanPhamInstance[ten_san_pham]", name);
    bodyFormData.append("sanPhamInstance[ma_san_pham]", code);
    bodyFormData.append("sanPhamInstance[so_hieu]", codeNumber);
    bodyFormData.append(
      "sanPhamInstance[mo_ta]",
      description ? description : ""
    );
    bodyFormData.append("sanPhamInstance[ghi_chu]", note ? note : "");
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/san-pham/cap-nhat/${id}`,
      bodyFormData
    );
  }
}
